/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
// utils
import { dispatch } from '../store';
import conObj from '../../utils/connection-assistant';

// ----------------------------------------------------------------------

const initialState = {
  inbox: [],
  unreadInboxCount: 0,
  memberBalance: {
    currencyCode: '',
    displayBalance: 0,
    balance: 0,
    isLoading: false,
  },
  gameCategsAndCompanies: JSON.parse(localStorage.getItem('gameCategsAndCompanies') || JSON.stringify({})),
  gameCategs: [],
  siteSettings: JSON.parse(localStorage.getItem('siteSettings') || JSON.stringify({})),
  promotions: JSON.parse(localStorage.getItem('promotions') || JSON.stringify({ data: [], categories: [] })),
  withdrawRequirement: {
    calculationType: '',
    current: 0,
    requirement: 0,
    isSufficient: false,
    allowWithdraw: false,
  },
};

const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setMemberBalance(state, action) {
      state.memberBalance = { ...state.memberBalance, ...action.payload };
    },
    setInbox(state, action) {
      state.inbox = action.payload;
    },
    setUnreadInboxCount(state, action) {
      state.unreadInboxCount = action.payload;
    },
    setGameCategsAndCompanies(state, action) {
      state.gameCategsAndCompanies = action.payload;
    },
    setGameCategs(state, action) {
      state.gameCategs = action.payload;
    },
    setSiteSettings(state, action) {
      state.siteSettings = action.payload;
    },
    setPromotions(state, action) {
      state.promotions = action.payload;
    },
    claimPromotion(state, action) {
      const newPromotions = JSON.parse(JSON.stringify(state.promotions?.data));

      if (newPromotions?.length) {
        state.promotions = {
          ...state.promotions,
          data: newPromotions.map((x) => {
            if (action.payload === x?.id) {
              return { ...x, is_claimable_now: false };
            }

            return x;
          }),
        };
      }
    },
    claimPromotionBanner(state, action) {
      const newBanner = JSON.parse(JSON.stringify(state.siteSettings?.sliders));

      if (newBanner?.length) {
        state.siteSettings = {
          ...state.siteSettings,
          sliders: newBanner.map((x) => {
            if (action.payload === x?.BannerSlide?.id) {
              return {
                BannerSlide: {
                  ...x?.BannerSlide,
                  is_claimable_now: false,
                },
              };
            }

            return x;
          }),
        };
      }
    },
    setWithdrawRequirement(state, action) {
      state.withdrawRequirement = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMemberBalance,
  setInbox,
  setUnreadInboxCount,
  setGameCategsAndCompanies,
  setGameCategs,
  setSiteSettings,
  setPromotions,
  claimPromotion,
  claimPromotionBanner,
  setWithdrawRequirement,
} = slice.actions;

export function getUserMemberBalance() {
  dispatch(setMemberBalance({ isLoading: true }));
  return conObj.post('members/balance.json').then((response) => {
    const { currency, display_balance: displayBalance, balance } = response.data?.data?.data;
    dispatch(setMemberBalance({ currencyCode: currency, balance, displayBalance, isLoading: false }));
    dispatch(setWithdrawRequirement(response.data?.data?.withdrawRequirement));
  });
}

export function getInbox(page = 0, limit = 20) {
  return conObj.post(`notifications.json?page=${page}&limit=${limit}`).then((response) => {
    dispatch(setInbox(response.data.data.data));
  });
}

export function getUnreadInboxCount(page = 0, limit = 20) {
  return conObj.post(`unread_notifications.json?page=${page}&limit=${limit}`).then((response) => {
    dispatch(setUnreadInboxCount(response.data.data.data.count));
  });
}

export function getGameCategsAndCompanies() {
  return conObj.post('games/game_categories.json', { isTokenOpt: true }).then((response) => {
    dispatch(setGameCategsAndCompanies(response?.data?.data?.data));
    localStorage.setItem('gameCategsAndCompanies', JSON.stringify(response?.data?.data?.data));
  });
}

export function getGameCategs() {
  return conObj.post('games/categories.json', { isTokenOpt: true }).then((response) => {
    dispatch(setGameCategs(response?.data?.data?.data));
  });
}

export function getSiteSettings() {
  return conObj.post('setting/site_configs.json').then((response) => {
    let _popupBanner = [];

    if (response.data?.data?.data?.popups.length > 0) {
      _popupBanner = response.data?.data?.data?.popups.map((x) => ({
        showPopup: true,
        ...x?.BannerSlide,
      }));
    }

    const _siteSettings = {
      ...response.data?.data?.data?.configs,
      announcement_text: response.data?.data?.data?.announcement_text,
      sliders: response.data?.data?.data?.sliders,
      languages: response.data?.data?.data?.languages,
      popups: _popupBanner,
    };
    dispatch(setSiteSettings(_siteSettings));
    localStorage.setItem('siteSettings', JSON.stringify(_siteSettings));
  });
}

export function getPromotions() {
  return conObj.post('promotion.json', { isTokenOpt: true }).then((response) => {
    const _promotion = { data: response.data?.data?.data, categories: response.data?.data?.categories };
    dispatch(setPromotions(_promotion));
    localStorage.setItem('promotions', JSON.stringify(_promotion));
  });
}

export function getProfile() {
  return conObj.post('profile.json').then((res) => {
    const { first_name, username, full_name, last_name, email, MemberPoint, MemberGroup } = res.data?.data?.data;
    const _user = {
      displayName: first_name,
      username,
      full_name,
      last_name,
      email,
      memberPoint: MemberPoint,
      memberGroup: MemberGroup,
    };

    localStorage.setItem('userInfo', JSON.stringify(_user));
  });
}
