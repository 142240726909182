import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Tooltip,
  DialogContent,
  ButtonGroup,
  Button,
  Stack,
  Grid,
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { RegisterForm } from '../../sections/auth/register';
import useTabs from '../../hooks/useTabs';
// import RegisterPhoneNumber from '../../sections/auth/register/RegisterPhoneNumber';
import { GoldBorderButton } from '../../components/nav-section/vertical/style';
import { ForgotPasswordForm } from '../../sections/auth/forgot-password';
import SocialIcons from '../../sections/about/SocialIcons';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  // maxWidth: 480,
  marginRight: 'auto',
  marginLeft: 'auto',
  // minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  // const { currentTab, setCurrentTab } = useTabs('0');
  const { translate } = useLocales();

  return (
    <ContentStyle spacing={4}>
      <Typography className="gold-text" variant="h4" fontWeight={900}>
        {translate('forgot_password')}
      </Typography>

      {/* <ButtonGroup>
        {registerSteps.map((x, idx) => (
          <Button
            key={idx}
            onClick={() => setCurrentTab(idx)}
            sx={{
              backgroundColor: (theme) =>
                idx.toString() === currentTab.toString() ? '#e7bd55' : theme.palette.primary.main,
              borderColor: 'black',
            }}
          />
        ))}
      </ButtonGroup> */}

      <ForgotPasswordForm />

      <Box sx={{ width: '100%' }}>
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <Typography className="gold-text" variant="h3">
            {translate('contact_us')}
          </Typography>
          <Box sx={{ width: { xs: '70%', sm: '60%', md: '50%', lg: '50%', xl: '50%' } }}>
            <SocialIcons
              GridContainerProps={{ sx: { justifyContent: 'center' }, spacing: { xs: 0, sm: 0, md: 1, lg: 2, xl: 2 } }}
            />
          </Box>
        </Stack>
      </Box>
    </ContentStyle>
  );
}
