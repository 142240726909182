import React, { useEffect, useState } from 'react';
import {
  Box,
  InputBase,
  Grid,
  NativeSelect,
  Stack,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';

import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../../redux/store';
import conObj from '../../../../utils/connection-assistant';
import { openTransactionsDialog } from '../../../../redux/slices/transactionsDialog';
import { openAddMemberBankDialog } from '../../../../redux/slices/addMemberBankDialog';
import { fCurrency, fNumberCommaSeparated } from '../../../../utils/formatNumber';
import { getUserMemberBalance } from '../../../../redux/slices/lookup';
import getPercentage from '../../../../utils/getPercentage';
import DepositWithdrawSkeleton from '../deposit/DepositSkeleton';

// import InsufficientBalanceDialog from './InsufficientBalanceDialog';
// import ConfirmWithdrawDrawer from './ConfirmWithdrawDrawer';

const TypographyCustom = styled(Typography)({
  color: 'white',
  fontSize: '14px',
});

const Withdraw = () => {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { memberBalance } = useSelector((x) => x.lookup);

  const [withdrawAmount, setWithdrawAmount] = useState('0');
  // const [withdrawInfoSaved, setWithdrawInfoSaved] = useState(false);
  // const [confirmWithdraw, setConfirmWithdraw] = useState(false);
  // const [openInsufficientBalance, setOpenInsufficientBalance] = useState(false);
  const [memberBankAccounts, setMemberBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  // const [amountInThousandths, setAmountInThousandths] = useState(false);
  const [currencyWithdraw, setCurrencyWithdraw] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minWithdrawLimit, setMinWithdrawLimit] = useState(0);
  const [maxWithdrawLimit, setMaxWithdrawLimit] = useState(0);
  const [withdrawLimitMessage, setWithdrawLimitMessage] = useState('');
  const [withdrawRequirement, setWithdrawrequirement] = useState(null);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);

  useEffect(() => {
    getMemberBankAccounts();
  }, []);

  // useEffect(() => {
  //   if (memberBalance?.currencyCode.toUpperCase() === 'MMK' || memberBalance?.currencyCode.toUpperCase() === 'VND') {
  //     setAmountInThousandths(true);
  //   }
  // }, [memberBalance]);

  // useEffect(() => {
  //   const _currencyWithdraw = memberBankAccounts.find((f) => f.id === selectedBankAccount)?.CurrencyWithdraw;
  //   if (_currencyWithdraw) {
  //     setCurrencyWithdraw(Object.values(_currencyWithdraw));
  //   } else {
  //     setCurrencyWithdraw(null);
  //   }
  // }, [selectedBankAccount]);

  const getMemberBankAccounts = () => {
    setIsLoadingInfo(true);
    conObj
      .post('members/banks.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            setMemberBankAccounts(res.data?.data?.data);
            // set selected
            setSelectedBankAccount(res.data?.data?.data[0]?.id);

            // set suggested currency withdraw amount
            setCurrencyWithdraw(Object.values(res.data?.data?.currencyWithdraw));

            setMinWithdrawLimit(res.data?.data?.minWithdrawLimit);
            setMaxWithdrawLimit(res.data?.data?.maxWithdrawLimit);

            setWithdrawLimitMessage(res.data?.data?.withdrawLimitMessage);

            setWithdrawrequirement(res.data?.data?.withdrawRequirement);
          } else {
            //
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
      })
      .finally(() => setIsLoadingInfo(false));
  };

  const submitWithdraw = () => {
    console.log(`getMaxWithdrawAmount = ${getMaxWithdrawAmount()}`);

    if (!withdrawAmount.trim()) {
      enqueueSnackbar(translate('x_is_required', { x: `${translate('withdraw')} ${translate('amount')}` }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    if (Number(memberBalance?.displayBalance) === 0) {
      enqueueSnackbar(`${translate('no_balance')}!`, { variant: 'error', autoHideDuration: 5000 });
      return;
    }

    const _memberBalance = parseFloat(memberBalance?.balance.replace(/[^0-9.]/g, ''));
    // const _memberBalance = amountInThousandths
    // ? parseFloat(memberBalance?.balance.replace(/[^0-9.]/g, '')) * 1000
    // : parseFloat(memberBalance?.balance.replace(/[^0-9.]/g, ''));
    if (parseFloat(withdrawAmount.toString().replaceAll(',', '')) > _memberBalance) {
      enqueueSnackbar(translate('amount_no_exceed_balance'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    if (currencyWithdraw) {
      if (parseFloat(withdrawAmount.toString().replaceAll(',', '')) < getMinWithdrawAmount()) {
        enqueueSnackbar(translate('minimum_withdraw_amount_is_x', { x: getMinWithdrawAmount() }), {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }
    }

    if (parseFloat(withdrawAmount.toString().replaceAll(',', '')) > getMaxWithdrawAmount()) {
      enqueueSnackbar(translate('maximum_withdraw_amount_is_x', { x: getMaxWithdrawAmount() }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    if (!selectedBankAccount) {
      enqueueSnackbar(translate('x_is_required', { x: translate('bank_account') }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    setIsSubmitting(true);

    const requestData = {
      'transaction-member_bank_id': selectedBankAccount,
      'transaction-amount': parseFloat(withdrawAmount.toString().replaceAll(',', '')),
      // 'transaction-amount': amountInThousandths
      // ? parseFloat(withdrawAmount.trim()) / 1000
      // : parseFloat(withdrawAmount.trim()),
      'transaction-note': '',
    };

    conObj
      .post('transactions/withdraws.json', requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            setIsSubmitting(false);
            enqueueSnackbar(res?.data?.data?.msg, { variant: 'success', autoHideDuration: 5000 });
            dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
          } else if (res.data?.data?.data?.validationErrors) {
            setIsSubmitting(false);
            enqueueSnackbar(res?.data?.data?.validationErrors.toString(), {
              variant: 'error',
              autoHideDuration: 5000,
            });
          } else {
            setIsSubmitting(false);
            enqueueSnackbar(res?.data?.data?.msg, { variant: 'error', autoHideDuration: 5000 });
          }
        }

        getUserMemberBalance();
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
        dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
      });
  };

  const getMinWithdrawAmount = () => {
    const _minimumWithdrawAmount = parseFloat(currencyWithdraw[0].replace(/[^0-9.]/g, ''));
    if (parseFloat(minWithdrawLimit) > 0) {
      return Math.max(parseFloat(minWithdrawLimit), parseFloat(_minimumWithdrawAmount));
    }

    return _minimumWithdrawAmount;
  };

  const getMaxWithdrawAmount = () => {
    const _maximumWithdrawAmount = parseFloat(currencyWithdraw[currencyWithdraw.length - 1].replace(/[^0-9.]/g, ''));
    if (parseFloat(maxWithdrawLimit) > 0) {
      return Math.min(parseFloat(maxWithdrawLimit), parseFloat(_maximumWithdrawAmount));
    }

    return _maximumWithdrawAmount;
  };

  const makeCurrencyWithdraw = (amount) => {
    const _amount = amount;
    // const _amount = amountInThousandths ? amount * 1000 : amount;
    return (
      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
        <Box
          onClick={() => setWithdrawAmount(_amount.toString())}
          sx={{
            backgroundColor: '#202a39',
            p: '11px 4px',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            borderColor: withdrawAmount === _amount ? '#2283f6' : 'transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        >
          <TypographyCustom sx={{ fontWeight: 700, wordBreak: 'break-all' }}>{`${fCurrency(
            _amount
          )}`}</TypographyCustom>
          {withdrawAmount === _amount && (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: 'absolute',
                bottom: 0,
                color: '#2283f6',
                height: '16px',
                right: '-1px',
                width: '17px',
                zIndex: 1,
              }}
            >
              <path d="M17 0L0 16H12C14.7614 16 17 13.7614 17 11V0Z" fill="currentColor" />
              <path
                d="M9.35493 11.6942L11.1083 12.8822L13.6758 9.00007"
                stroke="white"
                strokeWidth="0.822792"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </Box>
      </Grid>
    );
  };

  if (isLoadingInfo) return <DepositWithdrawSkeleton />;

  return (
    <Box>
      <Stack direction="row" alignItems={'center'}>
        <Typography
          sx={{ fontSize: '20px', fontWeight: 500, fontFamily: 'sans-serif', color: 'rgba(255, 255, 255, 0.85)' }}
        >
          {translate('available_balance')}
        </Typography>
        <Iconify icon={'ph:question'} width={20} height={20} sx={{ color: 'rgba(255, 255, 255, 0.85)', ml: '10px' }} />
      </Stack>

      {/* Balance */}
      {memberBalance?.isLoading && !memberBalance?.displayBalance ? (
        <CircularProgress size="1rem" />
      ) : (
        <Typography
          sx={{ color: '#f2c556', fontWeight: 900, fontSize: '20px' }}
        >{`${memberBalance?.currencyCode} ${memberBalance?.displayBalance}`}</Typography>
      )}
      {(withdrawRequirement?.calculation_type === 'turnover' || withdrawRequirement?.calculation_type === 'winover') &&
        withdrawRequirement?.requirement !== 0 && (
          <Stack direction="column" sx={{ p: 1, border: '1px solid #f2c556', borderRadius: 2 }}>
            <LinearProgress
              variant="determinate"
              value={getPercentage(withdrawRequirement?.current, withdrawRequirement?.requirement)}
            />
            <Typography sx={{ fontSize: '.75rem', color: '#fff' }}>
              {`${
                withdrawRequirement?.calculation_type === 'turnover' ? translate('turnover') : translate('balance')
              } ${translate('requirement')}`}
              :{' '}
            </Typography>
            <Typography sx={{ fontSize: '.75rem', color: '#fff', lineHeight: 1 }}>
              {fCurrency(withdrawRequirement?.current)} / {fCurrency(withdrawRequirement?.requirement)}
            </Typography>
          </Stack>
        )}

      {/* Amount */}
      <Box sx={{ mt: 1, mb: '10px' }}>
        <Stack direction="row" alignItems={'center'}>
          <Typography>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>
            {translate('amount')}
          </Typography>
          <Iconify icon={'uiw:information-o'} width={15} height={15} sx={{ color: 'rgb(95, 95, 95)', ml: '5px' }} />
        </Stack>

        <Stack
          direction="row"
          alignItems={'center'}
          sx={{
            borderRadius: '15px',
            border: '1px solid #fda92d',
            // boxShadow: '0 0 0 2px rgba(23,125,220,.2)',
            p: '4px 11px',
            mt: 1,
          }}
        >
          <TypographyCustom sx={{ mr: '4px', fontWeight: 700 }}>{memberBalance?.currencyCode}</TypographyCustom>
          <InputBase
            // type="number"
            name="withdrawAmount"
            value={fNumberCommaSeparated(withdrawAmount)}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            placeholder={translate('amount_withdrawn')}
            sx={{ color: 'white', fontWeight: 700, fontSize: '14px', width: '100%' }}
          />
        </Stack>
        {withdrawLimitMessage && (
          <Box
            dangerouslySetInnerHTML={{ __html: withdrawLimitMessage }}
            sx={{ fontSize: '.75rem', color: 'white', fontStyle: 'italic' }}
          />
        )}
      </Box>

      {memberBankAccounts.length > 0 ? (
        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography>
              <span style={{ color: 'red', marginRight: 5 }}>*</span>
              {translate('bank_account')}
            </Typography>
          </Stack>

          {/* <NativeSelect
            value={selectedBankAccount}
            onChange={(e) => setSelectedBankAccount(e.target.value)}
            disableUnderline={true}
            sx={{
              width: '100%',
              p: '4px 11px',
              mt: 1,
              borderRadius: '15px',
              border: '1px solid #2283f6',
              boxShadow: '0 0 0 2px rgba(23,125,220,.2)',
            }}
          >
            {memberBankAccounts?.map((item, index) => (
              <option key={index} value={item?.id}>
                {`${item?.account_number} - ${item?.Bank?.name}`}
              </option>
            ))}
          </NativeSelect> */}
          <Select
            value={selectedBankAccount}
            onChange={(e) => setSelectedBankAccount(e.target.value)}
            disableUnderline={true}
            sx={{ width: '100%', color: 'white', fontSize: '14px' }}
          >
            {memberBankAccounts?.map((item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
          {/* {errors?.bank && <span style={{ fontSize: '.75rem', color: 'red' }}>{errors?.bank}</span>} */}
        </Box>
      ) : (
        <Typography sx={{ fontSize: '.75rem', color: 'warning.main' }}>
          <Iconify icon={'uiw:information-o'} sx={{ mr: 0.5 }} />
          {translate('no_bank_account')}
        </Typography>
      )}

      <button
        style={{
          fontSize: '.75rem',
          padding: '5px',
          border: 'none',
          borderRadius: '5px',
          background: 'green',
          color: 'white',
          cursor: 'pointer',
          fontWeight: 600,
          marginTop: '10px',
        }}
        onClick={() => dispatch(openAddMemberBankDialog({ open: true, onClose: getMemberBankAccounts }))}
      >
        {translate('add_account')}
      </button>

      {currencyWithdraw && (
        <Box sx={{ mt: '10px' }}>
          <Grid container spacing={'20px'}>
            {currencyWithdraw.map((item) => makeCurrencyWithdraw(item))}
          </Grid>
        </Box>
      )}

      {/* <Box sx={{ mb: '10px' }}>
        <Stack direction="row" alignItems={'center'}>
          <Typography>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>Withdraw to
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems={'center'}
          sx={{
            borderRadius: '15px',
            border: '1px solid #2283f6',
            boxShadow: '0 0 0 2px rgba(23,125,220,.2)',
            p: '4px 11px',
            mt: 1,
          }}
        >
          <Iconify icon={'quill:creditcard'} width={25} height={25} sx={{ color: 'hsla(0,0%,100%,.85)', mr: '5px' }} />
          <InputBase
            value={withdrawInformation?.bankAccountNumber}
            sx={{ color: 'white', fontWeight: 700, fontSize: '14px', flexGrow: 1 }}
            onClick={() => setOpenWithdrawForm(true)}
          />
          <Iconify icon={'ep:arrow-right'} width={25} height={25} sx={{ color: 'hsla(0,0%,100%,.85)', ml: '5px' }} />
        </Stack>
      </Box> */}

      {Number(memberBalance?.displayBalance) === 0 && (
        <Box sx={{ py: 1 }}>
          <Typography sx={{ fontSize: '.75rem', color: 'error.main', float: 'right' }}>
            <Iconify icon={'uiw:information-o'} sx={{ mr: 0.5 }} />
            {translate('no_balance')}!
          </Typography>
        </Box>
      )}

      <Grid
        container
        // onClick={() => toggleDrawer(true)}
        onClick={() => (isSubmitting || withdrawRequirement?.allow_withdraw === false ? {} : submitWithdraw())}
        sx={{
          p: '8px 12px',
          position: 'relative',
          backgroundColor: 'rgb(237, 29, 73)',
          borderRadius: '10px',
          mt: '10px',
          cursor: isSubmitting ? 'progress' : 'pointer',
          opacity: isSubmitting || withdrawRequirement?.allow_withdraw === false ? 0.1 : 1,
        }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xl: 'inherit', lg: 'inherit', md: 'inherit', sm: 'inherit', xs: 'center' },
          }}
        >
          <Typography sx={{ fontSize: '18px', color: 'white', fontWeight: 700 }}>{translate('withdraw')}</Typography>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: 'flex', justifyContent: { xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'center' } }}
        >
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', color: 'white', fontWeight: 700, mr: 2, wordBreak: 'break-all' }}>{`${
              memberBalance?.currencyCode
            } ${fCurrency(withdrawAmount)}`}</Typography>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
              style={{ color: 'white' }}
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm154.7 454.5l-246 178c-5.3 3.8-12.7 0-12.7-6.5v-46.9c0-10.2 4.9-19.9 13.2-25.9L566.6 512 421.2 406.8c-8.3-6-13.2-15.6-13.2-25.9V334c0-6.5 7.4-10.3 12.7-6.5l246 178c4.4 3.2 4.4 9.8 0 13z" />
            </svg>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Withdraw;
