// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ms = {
  // login
  login: `LOG MASUK`,

  // register
  register: `DAFTAR`,

  // home
  game_partners: `Rakan Kongsi Permainan`,
  responsible_gaming: `Permainan Bertanggungjawab`,
  supported_bank: `Bank yang Disokong`,
  load_more: `Tambah lagi`,

  // game
  choose_your_favourite_x_games: `Pilih Permainan {{x}} Kegemaran Anda`,
  choose_your_favourite_x: `Pilih Permainan {{x}}`,
  launch: `PELANCARAN`,

  // notification
  notifications: `Pemberitahuan`,
  you_have_x_unread_messages: `Anda mempunyai mesej {{x}} yang belum dibaca`,

  // deposit/withdraw
  drop_or_select_file: `Lepaskan atau Pilih fail`,
  drop_files_here: `Lepaskan Fail di sini`,
  bank: `Bank`,
  bank_name: `Nama bank`,
  account_name: `Nama akaun`,
  account_number: `Nombor akaun`,
  bank_account: `Akaun bank`,
  something_went_wrong: `Sesuatu telah berlaku. Sila hubungi sokongan.`,
  deposit_amount_between_x_and_y: `Jumlah deposit mestilah antara {{x}} dan {{y}}`,
  minimum_amount_is_x_y: `Jumlah minimum ialah {{x}}{{y}}`,
  payment_method: `Kaedah Pembayaran`,
  mode: `Mod`,
  transfer_to: `Pemindahan Kepada`,
  select: `Pilih`,
  account: `Akaun`,
  amount: `Jumlah`,
  deposit: `Deposit`,
  withdraw: `Tarik diri`,
  amount_no_exceed_balance: `Jumlah Pengeluaran tidak boleh melebihi baki yang ada`,
  minimum_withdraw_amount_is_x: `Jumlah Pengeluaran Minimum ialah {{x}}`,
  available_balance: `Baki yang ada`,
  amount_withdrawn: `Jumlah yang dikeluarkan`,
  no_bank_account: `Anda tidak mempunyai sebarang akaun untuk menarik balik, Tambah sekarang`,
  add_account: `Tambah akaun`,
  account_name_must_be_4: `Nama Akaun mestilah lebih besar daripada 4 panjang`,
  please_select_bank: `Sila pilih Akaun Bank`,
  account_number_must_be_x: `Nombor akaun mestilah 10-14 panjang`,
  minimum_length_is_x: `Panjang minimum ialah {{x}}`,
  maximum_length_is_x: `Panjang maksimum ialah {{x}}`,
  withdraw_information: `Tarik balik Maklumat`,
  account_holder_name: `Nama Pemegang Akaun`,
  name_of_the_account_holder: `Nama pemegang akaun`,
  fill_in_bank_account: `Isi Akaun Bank`,
  please_upload_receipt: `Sila muat naik resit`,
  deposit_bonus: `Bonus Deposit`,
  bank_transfer: `Pemindahan bank`,
  upload_receipt: `Muat Naik Resit`,
  select_bonus: `Pilih Bonus`,
  no_promotion: `Tiada Promosi`,

  // transaction history
  transaction_history: `sejarah transaksi`,

  // bet history
  bet_history: `Sejarah Pertaruhan`,
  game_provider: `Penyedia Permainan`,
  bet_count: `Kiraan Pertaruhan`,
  bet_amount: `Jumlah Pertaruhan`,
  win_loss: `Menang/Kalah`,
  rebate: `Rebat`,
  rebates: `Rebat`,
  turnover: `Perolehan`,
  game_category: `Kategori Permainan`,

  // all
  all: `Semua`,
  x_is_required: `{{x}} diperlukan`,
  note: `Catatan`,
  save: `Jimat`,
  please_select_x: `Sila pilih {{x}}`,
  title: `Tajuk`,
  date: `Tarikh`,
  type: `Taip`,
  status: `Status`,
  remark: `Teguran`,
  clear: `Jelas`,
  date_from: `Tarikh dari`,
  date_to: `Tarikh Kepada`,
  category: `Kategori`,
  search: `Cari`,
  new: `Baru`,
  change_password: `Tukar kata laluan`,
  logout: `Log keluar`,
  username: `Nama pengguna`,
  password: `Kata laluan`,
  forgot_password: `Lupa kata laluan`,
  confirm: `SAHKAN`,
  dont_have_account: `Tiada akaun pengguna?`,
  contact_us: `Hubungi Kami`,
  game_menu: `Menu Permainan`,
  bonus: 'Bonus',
  no_data: 'Tiada Data',
  terms_and_condition: 'TERMA DAN SYARAT',
  game_list: 'Senarai Permainan',
  play: 'Main',
  games: 'Permainan',
  casino: 'Kasino',
  slots: 'Slot',
  card: 'Kad',
  fishing: 'Memancing',
  sport: 'Sukan',
  sports: 'Sukan',
  bonuses: 'Bonus',
  account_profile: `Account Profile`,
  email: `E-mel`,
  full_name: `Nama penuh`,
  phone_number: 'Nombor telefon',
  level: `Tahap`,
  bank_list: `Senarai Bank`,
  in_maintenance: `Dalam Penyelenggaraan`,
  birthday: `Hari Jadi`,
  birthday_bonus: `Masukkan hari lahir anda untuk bonus! 🎉🎁`,
  referral_code: `Kod rujukan`,
  invalid_x: `{{x}} tidak sah`,
  extra: `Tambahan`,
  referral: `Rujukan`,
  my_referral: `Rujukan Saya`,
  my_claim: `Tuntutan Saya`,
  claim_profit: `TUNTUTAN UNTUNG`,
  available_profit: `Keuntungan yang Tersedia`,
  claimed_profit: `Keuntungan yang Dituntut`,
  all_time_profit: `Keuntungan Sepanjang Masa`,
  referral_note_on_x: `Peroleh keuntungan setiap kali rakan anda bermain di {{x}}`,
  tier: `Peringkat`,
  commission: `Suruhanjaya`,
  referred_member: `Ahli yang dirujuk`,
  profit_earned: `Keuntungan Diperolehi`,
  account_information: `Maklumat Akaun`,
  invite_friends: `Menjemput kawan-kawan`,
  how_it_work: `Bagaimana ia berfungsi?`,
  invite_note: `Dapatkan bonus rujukan eksklusif apabila rakan anda mendaftar menggunakan kod rujukan anda!`,
  share_via: `Kongsi melalui`,
  referral_link: `Pautan Rujukan`,
  total_profit: `Jumlah Keuntungan`,
  copied: `Disalin`,
  nothing_to_claim: `Tiada apa-apa untuk dituntut`,
  claim_history: `Sejarah Tuntutan`,
  choose_date: `Pilih Tarikh`,
  select_both_date: `Pilih kedua-dua Tarikh Dari dan Kepada`,
  hello: 'Halo',
  my_downline: `Downline Saya`,
  downline: `Downline`,
  forgot_password_contact:
    'Sila hubungi sokongan pelanggan yang disediakan di bawah untuk membantu anda menukar kata laluan anda',
  submit: 'Hantar',
  current_password: 'Kata Laluan Semasa',
  new_password: 'Kata Laluan Baru',
  confirm_new_password: 'Sahkan Kata Laluan Baru',
  already_have_an_account: 'Sudah memiliki akaun?',
  share: 'Kongsi',
  more_info: 'Maklumat Lanjut',
  copy_link: 'Salin Pautan',
  share_to_friend: 'Kongsi Kepada Rakan',
  get_bonus: 'Dapatkan Bonus',
  copy_your_referral:
    'Salin pautan rujukan anda dan hantar ke media sosial untuk mendapatkan kredit dari downline anda!',
  last_x_digit_bank_ref_number: `{{x}} digit terakhir nombor Ruj Bank`,
  bank_ref_number_validation_x: `Nombor Rujukan Bank diperlukan dan mestilah {{x}} panjang aksara`,
  card_game: 'Permainan kad',
  e_sports: 'E-Sukan',
  instant_game: 'Permainan Segera',
  sort: 'Isih',
  name: 'Nama',
  default: 'Lalai',
  e_sports_bonus: 'E-Sukan Bonus',
  fishing_bonus: 'Memancing Bonus',
  casino_bonus: 'Kasino Bonus',
  sports_bonus: 'Sukan Bonus',
  slots_bonus: 'Slots Bonus',
  instant_game_bonus: 'Permainan Segera Bonus',
  card_game_bonus: 'Permainan kad Bonus',
  all_bonus: 'Semua Bonus',
  how_to_get_referral_bonus: 'Cara Mendapatkan Bonus Rujukan Sahabat?',
  steps_to_claim: 'Langkah-Langkah Untuk Tuntutan Bonus',
  share_your_referral_code:
    '1. Kongsi kod rujukan anda kepada rakan-rakan untuk mendaftar akaun melalui viber, messenger, telegram, facebook, twitter, atau instagram.',
  after_friend_register:
    '2. Selepas rakan mendaftar dengan AK47BET, kedua-dua rujukan dan ahli rujukan perlu membuat deposit untuk menuntut bonus ini.',
  refer_a_friend_bonus: '3. Pengiraan bonus rujukan sahabat:',
  turnover_played: 'Putaran dimainkan x Peratus Komisen = Bonus Rujukan Sahabat',
  example: 'Contoh:',
  turnover_formula_x: 'Putaran (1,000,000) x 0.002% (Peratus Komisen) = 2,000{{x}}',
  note_bonus_only: '*Nota: Bonus hanya boleh dituntut pada hari berikutnya selepas 12:00 pagi',
  note_bonus_only_x: '*Nota: Bonus hanya boleh dituntut pada hari berikutnya selepas {{x}}',
  access_dashboard:
    '4. Akses papan pemuka untuk menyemak keuntungan yang layak, tier komisen, sejarah tuntutan, dan downline.',
  click_this_button_to_claim: 'Klik butang ini untuk menuntut Bonus Rujukan Sahabat',
  commision_tier: 'Tier Komisen',
  member_downline: 'Ahli Downline',
  bonus_tutorial_video: 'Bonus Tutorial Video',
  popularity: 'Populariti',
  no_balance: 'Anda tidak mempunyai baki yang mencukupi untuk meneruskan',
  invalid_captcha: 'Captcha tidak sah',
  captcha: 'Captcha',
  claim_bonus: 'Tuntutan Bonus',
  claimed: 'Dituntut',
  bonus_claimed: 'Bonus Dituntut',
  online_banking: 'Online Banking',
  please_enter_email: 'Sila masukkan alamat e-mel anda untuk meneruskan proses tetapan semula kata laluan',
  please_select_a_bank: 'Sila pilih bank',
  deposit_from_bank: 'Deposit Dari Bank',
  from_selected_promotion: 'daripada promosi terpilih',
  network_error: 'Ralat Rangkaian',
  we_have_sent_otp: 'Kami telah menghantar OTP ke nombor telefon anda',
  your_otp_expire: 'OTP anda akan luput dalam',
  didnt_receive: 'Tidak menerima mesej?',
  send_again: 'Hantar semula',
  resend_again: 'Hantar semula dalam',
  invalid_verification: 'Kod Pengesahan Tidak Sah!',
  otp_expired: 'OTP telah luput, sila masukkan semula nombor telefon anda dan cuba lagi!',
  otp_expired_redirect: 'OTP telah luput! meneruskan ke log masuk',
  captcha_check_failed: 'Pemeriksaan Captcha Gagal!',
  verify_phonenumber: 'Sahkan Nombor Telefon',
  send_otp: 'Hantar OTP',
  success_verification:
    'Nombor Telefon anda telah disahkan! Sila isikan borang di bawah untuk menyiapkan pendaftaran anda.',
  dont_show_again: 'Jangan tunjuk lagi',
  next: 'Seterusnya',
  back: 'Kembali',
  close: 'Tutup',
  payment_draft:
    'Jika anda mengesahkan anda telah membayar, sila tunggu sebentar kerana pembayaran mungkin masih diproses',
  go_to_home: 'Pulang ke rumah',
  payout: `Pembayaran`,

  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  // account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default ms;
