/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import { S3_ASSETS_URL } from '../../config';

// Test Socials
// const siteSettings = {
//   config_telegram: 'https://google.com',
//   config_wechat: 'https://google.com',
//   config_whatsapp: 'https://google.com',
//   config_facebook: null,
//   config_messenger: null,
//   config_viber: null,
//   config_twitter: 'https://google.com',
//   config_instagram: 'https://google.com',
//   config_youtube: 'https://google.com',
//   config_line: null,
// };

const SocialIcons = ({ GridContainerProps = {}, isFullRow = false, itemsPerRow = 5, ImageStyles = {} }) => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const {
    // config_phonenumber,
    config_telegram,
    config_wechat,
    config_whatsapp,
    config_facebook,
    config_messenger,
    config_viber,
    config_twitter,
    config_instagram,
    config_youtube,
    config_line,
  } = siteSettings || {};

  const socialCount = useMemo(() => {
    let count = 0;

    if (siteSettings?.config_telegram) count += 1;
    if (siteSettings?.config_wechat) count += 1;
    if (siteSettings?.config_whatsapp) count += 1;
    if (siteSettings?.config_facebook) count += 1;
    if (siteSettings?.config_messenger) count += 1;
    if (siteSettings?.config_viber) count += 1;
    if (siteSettings?.config_twitter) count += 1;
    if (siteSettings?.config_instagram) count += 1;
    if (siteSettings?.config_youtube) count += 1;
    if (siteSettings?.config_line) count += 1;

    return count;
  }, [siteSettings]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  if (!socialCount) return <></>;

  return (
    <Grid container {...GridContainerProps}>
      {config_viber && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_viber)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/viber_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {/* {config_phonenumber && (
        <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12/3}>
          <Box
            onClick={() => openSocial(config_phonenumber)}
            component={m.div}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.8 }}
          >
            <Image sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }} src={`${S3_ASSETS_URL}home/social_icons/phone_gold.png`} />
          </Box>
        </Grid>
      )} */}
      {config_messenger && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_messenger)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/messenger_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_telegram && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_telegram)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/telegram_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_facebook && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_facebook)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/fb_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_twitter && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_twitter)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/twitter_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_instagram && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_instagram)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/instagram_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_line && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_line)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/line_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_wechat && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_wechat)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/wechat_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_whatsapp && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_whatsapp)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/whatsapp_gold.png`}
            />
          </Box>
        </Grid>
      )}
      {config_youtube && (
        <Grid
          item
          xs={isFullRow && socialCount > itemsPerRow ? 12 / socialCount : 12 / itemsPerRow}
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => openSocial(config_youtube)}
        >
          <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
            <Image
              sx={{ width: '100%', cursor: 'pointer', ...ImageStyles }}
              src={`${S3_ASSETS_URL}home/social_icons/youtube_gold.png`}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

SocialIcons.propTypes = {
  GridContainerProps: PropTypes.object,
  ImageStyles: PropTypes.object,
  isFullRow: PropTypes.bool,
  itemsPerRow: PropTypes.number,
};

export default SocialIcons;
