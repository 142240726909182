import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Stack, AppBar, Toolbar, Divider, Typography, Grid } from '@mui/material';
import { m } from 'framer-motion';
// hooks
import Image from '../../../components/Image';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
//
import LanguagePopover from './LanguagePopover';
import HeaderSocial from './HeaderSocial';
import HeaderLogo from './HeaderLogo';
import Announcement from './Announcement';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  // ...cssStyles(theme).bgBlur(),
  position: 'static',
  background: 'none',
  boxShadow: 'none',
  // height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH} + 1px)`,
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {};

export default function DashboardHeader() {
  const isSm = useResponsive('down', 'md');

  return (
    <>
      {isSm ? (
        <>
          <RootStyle sx={{ position: 'fixed' }}>
            {/* <HeaderSocial /> */}
            <HeaderLogo />
          </RootStyle>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '56px' }}>
            <Announcement />
          </Box>
        </>
      ) : (
        <RootStyle>
          <Grid container>
            <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Announcement />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* <LanguagePopover sx={{ color: 'white' }} /> */}
            </Grid>
          </Grid>
        </RootStyle>
      )}
    </>
  );
}
