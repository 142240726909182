import { LinearProgress } from '@mui/material';
import getPercentage from './getPercentage';

export default function vipProgressBar(isMobile) {
  const userProfile = JSON.parse(localStorage.getItem('userInfo') || {});

  const creditPoint = parseInt(userProfile?.memberPoint?.credit_point, 10);

  const nextLevelRequiredCreditPoint = parseInt(userProfile?.memberPoint?.next_level_required_credit_point, 10);

  if (nextLevelRequiredCreditPoint === 0) return '';

  return (
    <LinearProgress
      variant="determinate"
      value={getPercentage(creditPoint, nextLevelRequiredCreditPoint)}
      sx={{ height: isMobile ? '20px' : '4px' }}
    />
  );
}
