import { useEffect, useMemo, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Container } from '@mui/material';
// hooks
// routes
// sections
import { RegisterForm } from '../../sections/auth/register';
import { GoldBorderButton, LoginStylesByLocale } from '../../components/nav-section/vertical/style';
import { useDispatch } from '../../redux/store';
import { closeRegister, openLogin } from '../../redux/slices/authPages';
import SocialIcons from '../../sections/about/SocialIcons';
import useLocales from '../../hooks/useLocales';
// import RegisterPhoneNumber from '../../sections/auth/register/RegisterPhoneNumber';
// import RegisterOTPForm from '../../sections/auth/register/RegisterOTPForm';
// import { initVerifier } from '../../utils/firebaseUtil';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  // maxWidth: 480,
  marginRight: 'auto',
  marginLeft: 'auto',
  // minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const dispatch = useDispatch();

  const { translate, currentLang } = useLocales();

  // Remove OTP FOR VND
  // const [verification, setVerification] = useState({
  //   number: null,
  //   id: null,
  //   isVerified: false,
  // });

  // const ToDisplayForm = useMemo(() => {
  //   const { id, number, isVerified } = verification;
  //   if (!id && !number) return <RegisterPhoneNumber verification={verification} setVerification={setVerification} />;
  //   if (id && !isVerified) return <RegisterOTPForm verification={verification} setVerification={setVerification} />;
  //   if (isVerified) return <RegisterForm verification={verification} />;
  //   return <></>;
  // }, [verification]);

  // useEffect(() => {
  //   window.recaptchaVerifier = initVerifier();
  // }, []);

  return (
    <ContentStyle spacing={4}>
      <Typography className="gold-text" variant="h4" fontWeight={900}>
        {translate('register')}
      </Typography>

      {/* <ButtonGroup>
        {registerSteps.map((x, idx) => (
          <Button
            key={idx}
            onClick={() => setCurrentTab(idx)}
            sx={{
              backgroundColor: (theme) =>
                idx.toString() === currentTab.toString() ? '#e7bd55' : theme.palette.primary.main,
              borderColor: 'black',
            }}
          />
        ))}
      </ButtonGroup> */}
      {/* <>{ToDisplayForm}</> */}
      {/* {!verification?.id && !verification?.number ? ( */}
      <RegisterForm />
      <>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {translate('already_have_an_account')}
          </Typography>
          <GoldBorderButton
            size="small"
            onClick={() => {
              dispatch(openLogin());
              dispatch(closeRegister());
            }}
          >
            <Typography className="gold-text" variant={'body2'} fontWeight={700}>
              {translate('login')}
            </Typography>
          </GoldBorderButton>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Stack sx={{ alignItems: 'center', width: '100%' }}>
            <Typography className="gold-text" sx={LoginStylesByLocale[currentLang?.value || 'en']?.contactUs}>
              {translate('contact_us')}
            </Typography>
            <Box sx={{ width: { xs: '70%', sm: '60%', md: '50%', lg: '50%', xl: '50%' } }}>
              <SocialIcons
                GridContainerProps={{
                  sx: { justifyContent: 'center' },
                  spacing: { xs: 0, sm: 0, md: 1, lg: 1, xl: 1 },
                }}
                isFullRow={true}
              />
            </Box>
          </Stack>
        </Box>
      </>
      {/* ) : (
        ''
      )} */}

      {/* <div id="recaptcha-container" /> */}
    </ContentStyle>
  );
}
