import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import {  PATH_AUTH, PATH_BET_HISTORY } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import { useDispatch, useSelector } from '../../../redux/store';
import { openChangePwd } from '../../../redux/slices/authPages';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
// import getPercentage from '../../../utils/getPercentage';
// import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const isDesktop = useResponsive('up', 'lg');

  // const { withdrawRequirement } = useSelector((x) => x.lookup);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <MyAvatar /> */}
        <Box
          className="gold-border-button"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            borderWidth: 1,
            p: 0.2,
          }}
        >
          <Iconify icon="mdi:user" />
        </Box>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          pb: 0.5,
          mt: 1.5,
          ml: 0.75,
          minWidth: 200,
          width: 'unset',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
        disabledArrow={true}
      >
        <Box>
          <Stack sx={{ my: 1.5, px: 2.5 }} spacing={1}>
            <Typography variant="subtitle2" noWrap>
              {user?.displayName}
            </Typography>
            {!isDesktop && (
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                {user?.username ? (
                  <>
                    <Iconify icon="mdi:user" sx={{ mr: 0.5 }} />
                    <Typography variant="caption" noWrap>
                      {user?.username}
                    </Typography>
                  </>
                ) : (
                  ''
                )}
              </Box>
            )}
            {user?.email ? (
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                <Iconify icon="ic:baseline-email" sx={{ mr: 0.5 }} />
                <Typography variant="caption" noWrap>
                  {user?.email}
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* {(withdrawRequirement?.calculation_type === 'turnover' ||
          withdrawRequirement?.calculation_type === 'winover') &&
          withdrawRequirement?.current !== 0 &&
          withdrawRequirement?.requirement !== 0 && (
            <>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 1 }}>
                <Stack
                  direction="column"
                  sx={{ mt: 0.5, p: 0.5, border: '1px solid #f2c556', borderRadius: 1, width: '80%' }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={getPercentage(withdrawRequirement?.current, withdrawRequirement?.requirement)}
                  />
                  <Typography sx={{ fontSize: '.75rem', color: '#fff' }}>
                    {`${
                      withdrawRequirement?.calculation_type === 'turnover'
                        ? translate('turnover')
                        : translate('balance')
                    } ${translate('requirement')}`}
                    :{' '}
                  </Typography>
                  <Typography sx={{ fontSize: '.75rem', color: '#fff', lineHeight: 1 }}>
                    {fCurrency(withdrawRequirement?.current)} / {fCurrency(withdrawRequirement?.requirement)}
                  </Typography>
                </Stack>
              </Box>
              <Divider sx={{ borderStyle: 'dashed', mb: 1 }} />
            </>
          )} */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        */}

        <MenuItem
          to={'/user/account'}
          component={RouterLink}
          onClick={handleClose}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('Account')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/user/banks');
            handleClose();
          }}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('bank_account')}
        </MenuItem>

        <MenuItem to={'/history'} component={RouterLink} onClick={handleClose} sx={{ mx: 1, display: 'inline-block' }}>
          {translate('transaction_history')}
        </MenuItem>

        <MenuItem
          to={PATH_BET_HISTORY.root}
          component={RouterLink}
          onClick={handleClose}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('bet_history')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/referral');
            handleClose();
          }}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('referral')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(openChangePwd());
          }}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('change_password')}
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ mx: 1, display: 'inline-block' }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
