import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { AnimatePresence, m } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router';
import useResponsive from '../../../hooks/useResponsive';
import Image from '../../../components/Image';
import { varFade } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import { S3_ASSETS_URL } from '../../../config';
import { appLocale } from '../../../utils/const';
import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import { openLogin } from '../../../redux/slices/authPages';
import vipProgressBar from '../../../utils/vipProgressBar';
import { fNumberCommaSeparated } from '../../../utils/formatNumber';

const MobileNav = () => {
  const { isAuthenticated } = useAuth();

  const { siteSettings } = useSelector((x) => x.lookup);

  const dispatch = useDispatch();

  const location = useLocation();

  const isSm = useResponsive('down', 'md');

  const navigate = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const openDeposit = () => {
    if (isAuthenticated) {
      dispatch(
        openTransactionsDialog({
          open: true,
          isDeposit: true,
        })
      );
    } else {
      dispatch(openLogin());
    }
  };

  const openWithdraw = () => {
    if (isAuthenticated) {
      dispatch(
        openTransactionsDialog({
          open: true,
          isDeposit: false,
        })
      );
    } else {
      dispatch(openLogin());
    }
  };

  const openTelegram = () => {
    if (siteSettings?.config_telegram) {
      window.open(
        siteSettings?.config_telegram,
        '_blank'
        // 'noopener noreferrer'
      );
    }
  };

  if (!isSm) return <></>;

  return (
    <>
      <AnimatePresence>
        <Box component={m.div} {...varFade().inUp} sx={{ position: 'fixed', bottom: 0, mx: 1, zIndex: 2 }}>
          <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isAuthenticated && (
              <Image
                src={require('../../../assets/home/vip_logo.png')}
                sx={{ width: '12vw', height: '12vw', position: 'absolute', top: '-10vw' }}
              />
            )}
            <Grid container sx={{ minHeight: { sm: 'unset', xs: '14vw' }, alignItems: 'end' }}>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  pb: '2vw',
                }}
              >
                <Image src={`${S3_ASSETS_URL}home/navigation/MobileMenuBg.webp`} />
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    width: '90%',
                    justifyContent: 'space-between',
                    top: '-60%',
                  }}
                >
                  <Box
                    sx={{ width: '50%' }}
                    onClick={() => {
                      if (isAuthenticated) navigate('/bet-history');
                      else dispatch(openLogin());
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Image
                        sx={{ width: '100%', cursor: 'pointer' }}
                        src={`${S3_ASSETS_URL}home/navigation/${appLocale}/betting-history.png`}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: '50%', position: 'relative' }} onClick={() => openTelegram()}>
                    <Image
                      sx={{ width: '100%', cursor: 'pointer' }}
                      src={`${S3_ASSETS_URL}home/navigation/${appLocale}/telegram-contact.png`}
                    />
                    {appLocale === 'vn' && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'absolute',
                          bottom: '-5%',
                          m: 0,
                        }}
                      >
                        <Typography fontWeight={900} fontSize={{ xs: '2.8vw', sm: '2.8vw', md: '2.5' }}>
                          CSKH
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack direction="column">
                  {isAuthenticated && (
                    <Box onClick={() => navigate('/referral/groups')} sx={{ px: '2vw', position: 'relative' }}>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                          zIndex: 1,
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '2.1vw',
                          }}
                        >
                          {fNumberCommaSeparated(userInfo?.memberPoint?.credit_point)} /{' '}
                          {fNumberCommaSeparated(userInfo?.memberPoint?.next_level_required_credit_point)}
                        </Typography>
                      </Box>
                      {vipProgressBar(true)}
                    </Box>
                  )}
                  <Image
                    src={`${S3_ASSETS_URL}home/navigation/MobileNavi-2.png`}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  pb: '2vw',
                }}
              >
                <Image src={`${S3_ASSETS_URL}home/navigation/MobileMenuBg.webp`} />
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    width: '90%',
                    justifyContent: 'space-between',
                    top: '-60%',
                  }}
                >
                  <Box sx={{ width: '50%' }}>
                    <Image
                      sx={{ width: '100%', cursor: 'pointer' }}
                      onClick={openDeposit}
                      src={`${S3_ASSETS_URL}home/navigation/${appLocale}/deposit-icon.png`}
                    />
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Image
                      sx={{ width: '100%', cursor: 'pointer' }}
                      onClick={openWithdraw}
                      src={`${S3_ASSETS_URL}home/navigation/${appLocale}/withdraw-icon.png`}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AnimatePresence>
    </>
  );
};

export default MobileNav;

export const MobileNavSpacer = () => {
  const isSm = useResponsive('down', 'md');

  if (!isSm) return <></>;

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', opacity: 0 }}>
      <Grid xs={4}>
        <Image
          src={`${S3_ASSETS_URL}home/navigation/MobileNavi-2.png`}
          sx={{ cursor: 'pointer' }}
          // onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        />
      </Grid>
    </Grid>
  );
};
